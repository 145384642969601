import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminState } from './admin.state';

export const adminFeatureKey = 'admin';

export const selectAdminFeature = createFeatureSelector<AdminState>(adminFeatureKey);

export const selectAllAdminMaps = createSelector(selectAdminFeature, state => state.adminMaps);

export const selectLoadingAdminMaps = createSelector(
  selectAdminFeature,
  state => state.loadingAdminMaps
);
